import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { User } from '../../shared/models/user.models';
import { ApiService } from '../http/api.service';

import { API_URL } from 'src/app/shared/service/api.constant';
import { UserDetail } from 'src/app/store/auth/auth.model';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<User>;

  constructor(private http: ApiService, private router: Router) {
    this.currentUserSubject = new BehaviorSubject(
      this.currentUserValue,
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): string {
    return localStorage.getItem('currentUser');
  }

  public get isSetPassword(): boolean {
    return localStorage.getItem('is_set_password') === 'true';
  }

  public get isPasswordValid(): boolean {
    return (
      (localStorage.getItem('is_password_valid') || 'true') === 'true'
    );
  }

  public get httpHeader() {
    if (
      localStorage.getItem('JWTKey') &&
      this.router.url.includes('/approval/')
    ) {
      return { Authorization: localStorage.getItem('JWTKey') };
    }
    return {
      Authorization: `Bearer ${localStorage.getItem('currentUser')}`,
    };
  }

  login(username: string, password: string): Observable<User> {
    return this.http
      .post<User>('/api/token-auth/', {
        username,
        password,
      })
      .pipe(
        map((user) => {
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem(
            'currentUser',
            JSON.stringify(user.access),
          );
          if (user.session_name) {
            sessionStorage.setItem('session_name', user.session_name);
          }

          this.currentUserSubject.next(user);
          return user;
        }),
      );
  }

  logout() {
    return this.http.post('/api/logout/', {
      refresh: localStorage.getItem('refresh'),
      session_name: sessionStorage.getItem('session_name'),
    });
  }

  getPersonDetail(id: number): Observable<UserDetail> {
    return this.http.get<UserDetail>(API_URL.people + id + '/');
  }

  forgotPassword(email: { email: string }): Observable<any> {
    return this.http.post(API_URL.reset_password, email);
  }

  initialData(data) {
    return this.http.patch(API_URL.initial, data);
  }

  validateResetPasswordToken(data) {
    return this.http.post(API_URL.reset_password_validate, data);
  }

  resetPassword(data) {
    return this.http.post(API_URL.reset_password_confirm, data);
  }

  getPasswordPolicy() {
    return this.http.get('/api/pw-policy/');
  }
}
