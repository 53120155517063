s<ng-template #deleteConfirmationModal
    let-c="close">
  <app-modal-confirmation [title]="header"
      [body]="text"
      (closeModal)="c()"
      (confirmModal)="submit()">
  </app-modal-confirmation>
</ng-template>

<i *ngIf="type==='icon' && !useNewDesign"
    class="fas fa-trash icon-btn red p-0"
    (click)="$event.stopPropagation(); open(deleteConfirmationModal)">
  <i class="fas fa-trash"
      *ngIf="!useNewDesign"></i>
</i>

<svg-icon *ngIf="type==='icon' && useNewDesign"
    class="size-svg pointer"
    src="assets/svg/delete.svg"
    (click)="open(deleteConfirmationModal)"></svg-icon>

<!-- <img class="pointer" *ngIf="type==='icon' && useNewDesign" src="assets/images/icons/delete.svg"
     (click)="open(deleteConfirmationModal)"> -->

<button *ngIf="type === 'text'"
    class="btn btn-danger p-btn"
    (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-w-100'"
    class="btn btn-danger w-100"
    (click)="open(deleteConfirmationModal)">
  {{ deleteMessage|translate }}
</button>

<button *ngIf="type === 'text-memo'"
    class="btn btn-danger del-btn w-auto"
    (click)="open(deleteConfirmationModal)">
  {{ "MEMOS.DEL-MEMO" | translate }}
</button>

<div *ngIf="type === 'x-icon'"
    (click)="open(deleteConfirmationModal)">
  <i class="fas fa-times pointer"></i>
</div>

<button *ngIf="type === 'tag'"
    class="tag red pointer"
    (click)="open(deleteConfirmationModal)">
  {{ "CREATE-USER.DELETE"|translate }}
</button>
