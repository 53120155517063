import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { MemoListDetail } from 'src/app/modules/memos/model/memo.model';
import { Memo } from 'src/app/store/memo/memo.model';
import { NotificationService } from '../../service/notification.service';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { Subscription } from 'rxjs';
export type ActionType = 'approve' | 'reject' | 'terminate';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
})
export class ConfirmModalComponent implements OnDestroy {
  @Output() confirmOtpSubmit = new EventEmitter();
  @Output() closeModal = new EventEmitter();
  @Output() otpChange = new EventEmitter<string>();
  @Input() memoDetail;
  @ViewChild('confirmSuccess', { static: true })
  confirmSuccess: ElementRef;
  @ViewChild('confirmAlertMessage', { static: true })
  confirmAlertMessage: ElementRef;

  memoList: MemoListDetail;
  memos: Memo;
  themeList: ThemeList;
  actionType: ActionType;
  textMessage = '';

  themeSubscription: Subscription[] = [];
  constructor(
    private store: Store,
    public modalService: NgbModal,
    private notification: NotificationService,
    private router: Router,
    private themeService: ThemeService,
    private translate: TranslateService,
  ) {
    this.themeSubscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  /**
   * Open the confirmation modal
   * @param action This parameter specify wording and UI.
   */
  open(action: ActionType): void {
    this.actionType = action;
    if (this.actionType === 'approve') {
      this.textMessage = this.translate.instant(
        'APPROVAL.APPROVAL-DOCUMENT',
      );
    } else if (this.actionType === 'reject') {
      this.textMessage = this.translate.instant(
        'APPROVAL.REJECT-DOCUMENT',
      );
    } else {
      this.textMessage = this.translate.instant(
        'APPROVAL.TERMINATE-DOCUMENT',
      );
    }
    this.modalService.open(this.confirmSuccess, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  openOtpModal(): void {
    this.modalService.open(this.confirmAlertMessage, {
      backdrop: 'static',
      centered: true,
      keyboard: false,
    });
  }
  close() {
    this.modalService.dismissAll();
    this.closeModal.emit();
  }

  previousPage() {
    this.closeModal.emit();
    this.modalService.dismissAll();
    this.notification.updateNotificationCount();
  }

  goToMemoDetail() {
    const id = this.store.selectSnapshot(
      (s) => s.memoCreationData.id,
    );
    this.router.navigate(['memos', 'preview', id]);
    this.modalService.dismissAll();
  }
  onConfirmClick() {
    this.confirmOtpSubmit.emit();
  }
}
