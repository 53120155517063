<ng-select [items]="people$ | async"
    bindLabel="full_name"
    [loading]="peopleLoading"
    placeholder="{{'MEMOS.SELECT-APPROVER'|translate}}"
    [clearable]="clearable"
    [disabled]="disabled || disabledApprover"
    [(ngModel)]="approval"
    (change)="onDropdownChange($event);"
    [typeahead]="peopleInput$"
    [minTermLength]="2"
    typeToSearchText="{{ 'user.type-search' | translate }}"
    [notFoundText]="'SELECT.No items found'|translate">
</ng-select>
<ng-template #certificateStatusTemplate>
  <div class="validity-status"
      [ngSwitch]="certificateStatus">
    <ng-container *ngSwitchCase="'valid'">
      <span class="fas fa-check-circle text-success"></span>
      <label class="text-success">{{ 'CERTIFICATE.VALID' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'expired'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.EXPIRED' | translate }}</label>
    </ng-container>
    <ng-container *ngSwitchCase="'not_created'">
      <span class="fas fa-times-circle text-danger"></span>
      <label class="text-danger">{{ 'CERTIFICATE.NOT-CREATED' | translate }}</label>
    </ng-container>
  </div>
</ng-template>
<ng-container *ngIf="ddocEnable">
  <app-signing-law-section-radio [signerId]="approval?.person"
      [certStatusSec26]="approval?.m26_certificate_status"
      [certStatusSec28]="approval?.m28_certificate_status"
      [(ddocLawSection)]="ddocLawSection"
      (ddocLawSectionChange)="ddocLawSectionChange.emit($event)"
      [freeLawSelection]="freeLawSelection"
      [allowLOAChange]="allowLOAChange"
      (signerRejected)="approvalChange.emit({ person: null })">
  </app-signing-law-section-radio>
</ng-container>
